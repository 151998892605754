import 'headroom.js';
import 'onscreen';
import 'nouislider';
//import 'waypoints';
import 'waypoints/lib/noframework.waypoints.js';
//import 'owl.carousel';
import 'jarallax';
import 'countup.js';
import 'jquery-countdown';
import 'smooth-scroll';
import 'prismjs';